<template>
  <section-wrapper :style="containerStyles" class="hero__container">
    <template #header-additional>
      <div class="section__title gradient-text" v-html="data.hero_title" />
    </template>

    <div class="content__container">
      <div class="content__description" v-html="data.hero_text" />
    </div>

    <ul v-if="data.buttons.length" class="buttons__container">
      <li
        v-for="button in data.buttons"
        :key="button.id"
        class="button__container"
      >
        <app-button
          :page="button.page.data"
          :url="button.url"
          :variant="button.variant"
          class="button"
        >
          {{ button.text }}
        </app-button>
      </li>
    </ul>
  </section-wrapper>
</template>

<script lang="ts" setup>
import { computed } from "#imports";
import AppButton from "~/modules/shared/components/AppButton.vue";
import type { HeroComponentType } from "~/modules/shared/components/hero/HeroComponent.types";
import SectionWrapper from "~/modules/shared/components/SectionWrapper.vue";

const props = defineProps<{
  data: HeroComponentType;
}>();

const containerStyles = computed(() => {
  const styles: Record<string, string> = {};

  if (props.data.background_image?.data) {
    styles["backgroundImage"] =
      `url(${props.data.background_image.data?.attributes?.url})`;
  }

  return styles;
});
</script>

<style lang="scss" scoped>
.hero__container {
  margin-bottom: 11rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  :deep(.section-inner__container) {
    @apply py-64;
  }
}

.section__title {
  @apply w-full text-center;
}

.content__container {
  @apply flex mt-20;

  .content__description {
    @apply w-full md:mx-32 xl:mx-52 text-primary-400 text-center;
  }
}

.buttons__container {
  @apply flex flex-col md:flex-row w-full items-center justify-center mt-20;

  .button__container {
    @apply w-full md:w-auto px-8 mb-8 md:mb-0;
  }

  .button {
    @apply w-full;

    @screen md {
      min-width: 21.4rem;
    }
  }
}
</style>
